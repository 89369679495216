import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Box } from "../../components/Core";

const AppIcon = styled.img`
  border-radius: 20%;
  border: 1px solid #ddd;
  transition: 0.4s;

  &:hover {
    transform: scale(0.8) rotate(-16deg);
    box-shadow: 0 32px 74px rgba(68, 77, 136, 0.2);
    overflow: hidden;
  }
`

const RotateAppIcon = ({ link = "/", imgSrc, alt = "", ...rest }) => {
  return (
    <>
      <Box {...rest}>
        <Link to={link} className="d-block">
          <AppIcon src={imgSrc} alt={alt} className="w-100" />
        </Link>
      </Box>
    </>
  );
};
export default RotateAppIcon;
